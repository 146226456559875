<template>
  <div>
    <ca-header
      :heading="`Fahrzeug Favoriten`"
      :column-layout="true"
    />
    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="car-favorite"
        :base-query="baseQuery"
        @length="length = $event"
        @row-clicked="(item) => $router.push({name: 'CarPoolDetail', params: {carId: item.car._id}})"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable'
import formatDate from '../../filters/formatDate.js'
export default {
  name: 'CarList',
  components: {
    DataTable,
    CaHeader
  },
  data: () => ({
    length: undefined,
    fields: [
      {
        label: 'Kennzeichen',
        key: 'car.licensePlate'
      },
      {
        label: 'Hinzugefügt am',
        key: 'createdAt',
        formatter: value => formatDate(value, 'DD.MM.YYYY HH:mm'),
        filter: {
          type: 'DATERANGE'
        }
      }
    ],
    statusFilter: 1,
    numberOfCars: null,
    loading: false
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    baseQuery () {
      const baseQuery = {
        userId: this.me._id
      }

      return baseQuery
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async fetchData () {
      try {
        this.loading = true
      } catch (error) {
        console.error(error)
      } finally {
        this.loading = false
      }
    }

  }
}
</script>

<style scoped lang="scss">

</style>
